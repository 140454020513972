html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --clr-bg: hsl(0, 0%, 100%);
  --on-bg: hsl(0, 0%, 0%);

  --clr-bg-elevated: hsl(0, 0%, 95%);
  --on-bg-elevated: hsl(0, 0%, 2%);

  --clr-bg-elevated-2: hsl(0, 0%, 90%);
  --on-bg-elevated-2: hsl(0, 0%, 2%);

  --clr-primary: hsl(210, 100%, 50%);
  --clr-primary-dark: hsl(210, 100%, 25%);
  --on-primary: hsl(210, 0%, 100%);

  --clr-accent: hsl(285, 100%, 50%);
  --clr-accent-dark: hsl(285, 100%, 40%);
  --on-accent: hsl(285, 0%, 100%);

  --clr-success: hsl(120, 100%, 50%);
  --clr-success-dark: hsl(120, 100%, 25%);
  --on-success: hsl(120, 0%, 100%);

  --clr-warning: hsl(30, 100%, 50%);
  --clr-warning-dark: hsl(30, 100%, 25%);
  --on-warning: hsl(30, 0%, 100%);

  --clr-danger: hsl(0, 100%, 50%);
  --clr-danger-dark: hsl(0, 100%, 25%);
  --on-danger: hsl(0, 0%, 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --clr-bg: hsl(0, 0%, 0%);
    --on-bg: hsl(0, 0%, 100%);

    --clr-bg-elevated: hsl(0, 0%, 15%);
    --on-bg-elevated: hsl(0, 0%, 95%);

    --clr-bg-elevated-2: hsl(0, 0%, 30%);
    --on-bg-elevated-2: hsl(0, 0%, 90%);
  }
}